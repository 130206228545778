import { createRouter, createWebHistory } from "vue-router";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import TheLogin from "@/components/auth/TheLogin.vue";
import CompanySettingsForm from "@/views/CompanySettingsForm.vue";
import UserSettingsView from "@/views/UserSettingsView.vue";
import Dashboard from "@/views/Dashboard.vue";
import KundenView from "@/views/KundenView.vue";
import MailConfirm from "@/api/mailconfirm.vue";
import ResetPasswordForm from "@/api/ResetPasswordForm.vue";
import ItemsView from "@/views/ItemsView.vue";
import InvoicesView from "@/views/InvoicesView.vue";
import CreditNoteView from "@/views/CreditNoteView.vue";
import OffersView from "@/views/OffersView.vue";
import OrdersView from "@/views/OrdersView.vue";
import LegalTextsSettingsForm from "@/views/LegalTextsSettingsForm.vue";
import Shipping from "@/views/Shipping.vue";
import NoCustomers from "@/views/NoCustomers.vue";
import Settings from "@/views/Settings.vue";
import InitialSettings from "@/views/InitialSettings.vue";
import Tip from "@/components/Tips/Tip.vue";
import Handbook from "@/components/handbook/Handbook.vue";
import FastStart from "@/components/FastStart.vue";
import EtikettenGenerator from "@/components/EtikettenGenerator.vue";
import ShippingPrepareView from "@/views/ShippingPrepareView.vue";
import Businessletter from "@/views/BusinessLetter.vue";
import UploadFile from "@/components/UploadFile.vue";
import DocumentSettings from "@/views/DocumentSettings.vue";
import EmailEditDialog from "@/views/EmailEditDialog.vue";
import CategoryDialog from "@/views/CategoryDialog.vue";
import InventurScanner from "@/views/InventurScanner.vue";
import InventurView from "@/views/InventurView.vue";
import ItemAuswahlListe from "@/views/ItemAuswahlListe.vue";
import Hw from "@/components/handbook/Hw.vue";
import TicketsView from "@/views/TicketsView.vue";
import Importante from "@/components/Importante.vue";
const routes = [
  {
    path: "/",
    name: "login",
    component: TheLogin,
    meta: { requiresAuth: false },
  },
  {
    path: "/mail-confirm/:token", // Der Token-Parameter wird als dynamischer Teil der URL definiert
    name: "MailConfirm",
    component: MailConfirm,
    meta: { requiresAuth: false },
  },
  {
    path: "/faststart",
    name: "faststart",
    component: FastStart,
    meta: { requiresAuth: true },
  },
  {
    path: "/importante",
    name: "importante",
    component: Importante,
    meta: { requiresAuth: true },
  },
  {
    path: "/itemAuswahlListe",
    name: "itemAuswahlListe",
    component: ItemAuswahlListe,
    meta: { requiresAuth: true },
  },
  {
    path: "/categoryDialog",
    name: "categoryDialog",
    component: CategoryDialog,
    meta: { requiresAuth: true },
  },
  {
    path: "/ticketsView",
    name: "ticketsView",
    component: TicketsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/inventurScanner",
    name: "inventurScanner",
    component: InventurScanner,
    meta: { requiresAuth: true },
  },
  {
    path: "/inventurView",
    name: "inventurView",
    component: InventurView,
    meta: { requiresAuth: true },
  },
  {
    path: "/hw",
    name: "hw",
    component: Hw,
    meta: { requiresAuth: true },
  },
  {
    path: "/upload",
    name: "upload",
    component: UploadFile,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/documentSettings",
    name: "documentSettings",
    component: DocumentSettings,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoicesView",
    name: "invoicesView",
    component: InvoicesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/creditnoteView",
    name: "creditnoteView",
    component: CreditNoteView,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoicesView",
    name: "invoicesView",
    component: InvoicesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/offersView",
    name: "offersView",
    component: OffersView,
    meta: { requiresAuth: true },
  },
  {
    path: "/ordersView",
    name: "ordersView",
    component: OrdersView,
    meta: { requiresAuth: true },
  },
  {
    path: "/emailEditDialog",
    name: "emailEditDialog",
    component: EmailEditDialog,
    meta: { requiresAuth: true },
  },
  {
    path: "/itemsEditView",
    name: "itemsEditView",
    component: ItemsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/businessLetter",
    name: "businessLetter",
    component: Businessletter,
    meta: { requiresAuth: true },
  },
  {
    path: "/userSettingsView",
    name: "userSettingsView",
    component: UserSettingsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/companySettingsForm",
    name: "companySettingsForm",
    component: CompanySettingsForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/initialSettings",
    name: "initialSettings",
    component: InitialSettings,
    meta: { requiresAuth: true },
  },
  {
    path: "/legalTextsSettingsForm",
    name: "legalTextsSettingsForm",
    component: LegalTextsSettingsForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/shipping",
    name: "shipping",
    component: Shipping,
    meta: { requiresAuth: true },
  },
  {
    path: "/nocustomers",
    name: "NoCustomers",
    component: NoCustomers,
    meta: { requiresAuth: true },
  },
  {
    path: "/kundenView",
    name: "kundenView",
    component: KundenView,
    meta: { requiresAuth: true },
  },
  {
    path: "/shippingPrepareView",
    name: "shippingPrepareView",
    component: ShippingPrepareView,
    meta: { requiresAuth: true },
  },
  {
    path: "/itemsView",
    name: "ItemsView",
    component: ItemsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/etikettenGenerator",
    name: "EtikettenGenerator",
    component: EtikettenGenerator,
    meta: { requiresAuth: true },
  },
  {
    path: "/handbook",
    name: "Handbook",
    component: Handbook,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: "/reset-password/:token", // Definiere einen Routenparameter ":token"
    name: "reset-password",
    component: ResetPasswordForm,
    meta: { requiresAuth: false },

  },
  {
    path: "/tip",
    name: "tip",
    component: Tip,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = userStore();
  const { dashboardRights, isAuthenticated } = storeToRefs(store);

  console.log("🚀 ~ file: index.js:258 ~ from:", from);
  console.log("🚀 ~ file: index.js:275 ~ to:", to);
  console.log("🚀 ~ file: index.js:262 ~ to.meta.requiresAuth:", to.meta.requiresAuth);
  console.log("🚀 ~ file: index.js:264 ~ isAuthenticated.value:", isAuthenticated.value);

  if (to.meta.requiresAuth && !isAuthenticated.value) {
    console.log("Authentifizierung erforderlich");
    if (to.name !== "login") {
      next("/"); // Weiterleitung zur Login-Seite
    } 
    return next(); // Keine Endlosschleife auf der Login-Seite
  }

    // Verhindere Rückleitung zur vorherigen Seite nach Abmeldung
    if (!isAuthenticated.value && to.name === "login" && from.name !== "login") {
      console.log("Benutzer kommt von einer anderen Seite und geht jetzt zur Login-Seite");
      return next(); // Bleibt auf der Login-Seite ohne Endlosschleife
    }
  
  if (to.name === "login") {
    if (isAuthenticated.value) {
      console.log("Benutzer ist bereits angemeldet");
      return next(dashboardRights.value ? "/dashboard" : "/invoicesView");
    }
    console.log("Benutzer ist nicht angemeldet");
    return next(); // Weiterleitung zur Login-Seite, wenn der Benutzer nicht angemeldet ist
  }
  
  console.log("Weiterleitung zu der angeforderten Seite");
  next();
  
});



export default router;
