
/*
store.setDebug(true); // aktiviert die Debugausgabe
store.setDebug(false); // deaktiviert die Debugausgabe

importiert wird das in anderen Vue Dateien mit 

import { debugToast } from "@/components/DebugToastAusgabe.vue";
import { debugResponse } from "@/components/DebugToastAusgabe.vue";
import { debugToast, debugResponse } from "@/components/DebugToastAusgabe.vue";

und dann kann es in der Methode  aufgerufen werden mit debugToast("success", "Erfolg", "Die Daten wurden erfolgreich geladen"); oder mit
abfangen der catch meldung mit error.message und dann debugToast("error", "Fehler", error.message); 

oder mit response nachrichten den statuscode mit debugResponse(response); 

*/
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';


export const debugResponse = (toast, response) => {

console.log("🚀 ~ file: useDebugToast.js:23 ~ response:", response)



    const store = userStore();

    if (!store.debug) {
        return;
    }
    if (!response || response === undefined || response === null || response === "") {
        return;
    }
    console.log("🚀 ~ ~ response:", response)

    if (response?.status > 200) {
        debugToast(toast, "warning", response?.status , response.message);
    } else if (response?.status > 400){
        if (response != null)
            debugToast(toast, "error", "Fehler " + response?.status, response.message);
    }
};

export const errorToast = (toast, error) => {
    const store = userStore();

    if (!store.debug) {
        return;
    }
    console.error("errorToast DEBUG", error);
    debugToast(toast, "error", 'c:'+error?.code, 'm'+error?.message);
};

export const debugToast = (toast, severity, summary, detail) => {

    if (!summary && !detail) return;

    const store = userStore();

    if (!store.debug) {
        return;
    }
    toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
    });
};
